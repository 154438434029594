export const testimonials = [
  {
    name: 'Wyldeplants',
    image: '/img/IMG_1434.png',
    company: 'Wyldeplants',
    text: "When I decided to launch my Australian based business, I reached out to Tradly to assist me in developing a very specific model of marketplace app. The Tradly Team put together a completely customised app that was to my business's unique specifications in a short timeframe, creating a fantastic customer experience every step of the way. I was, and continue to be, blown away by the way that Tradly has streamlined the white label app process, making an app presence accessible to businesses of all sizes.",
    Country: 'Australia 🇦🇺',
    Language: 'English',
    RevenueModel: 'Commission+Subscription',
  },
  {
    name: 'Ventco',
    image: '/img/IMG_1434.png',
    company: 'Ventco Marketplace',
    text: "Tradly est une platforme proposant énormément de services et cela m'a permis de gagner énormément de temps sur la construction d'un projet de marketplace. De plus Tradly comprend un accès membre sur Slack, qui nous permet de communiquer très facilement, l'équipe est au top, toujours disponible pour nous aider. Je recommande vraiment cette plate-forme.",
    Country: 'Colombia 🇨🇴',
    Language: 'Spanish',
    RevenueModel: 'Listing Markup fixed fee + Listing commission %',
  },
  {
    name: 'Velvet',
    image: '/img/IMG_1438-768x1365.png',
    company: 'Velvet Marketplace',
    text: 'I am a developer, but I prefer to use Tradly because it allows me to get on the market quickly, without waiting 6 months of development.',
    Country: 'Malta 🇲🇹',
    Language: 'English',
    RevenueModel: 'Commission %',
  },
  {
    name: 'Waneko',
    image: '/img/IMG_1446-768x1365.png',
    company: 'Waneko Marketplace',
    text: 'Integrated a new payment gateway "PayDunya" and activated a subscription mechanism where their seller will need to have an active subscription before selling items in the marketplace.',
    Country: 'Senegal 🇸🇳',
    Language: 'French',
    RevenueModel: 'Subscription Fee',
  },
  // {
  //   name: 'Nammakada',
  //   image: '/img/nammakada-app.png',
  //   company: 'Nammakada Marketplace',
  //   text: 'A local marketplace in India helping sellers connect with buyers seamlessly.',
  //   Country: 'India 🇮🇳',
  //   Language: 'English',
  //   RevenueModel: 'Commission-based',
  // },

  // {
  //   name: 'Local Hong Kong Marketplace',
  //   image: '/img/IMG_1453-768x1365.png',
  //   company: 'Hong Kong Marketplace',
  //   text: 'A local marketplace solution for Hong Kong-based businesses.',
  //   Country: 'Hong Kong 🇭🇰',
  //   Language: 'Chinese',
  //   RevenueModel: 'Commission-based',
  // },
  // {
  //   name: 'US Marketplace App',
  //   image: '/img/Image-from-iOS-4-768x1505.png',
  //   company: 'Tradly Headless Solution',
  //   text: "Built on Tradly's headless solution, enabling businesses to quickly launch marketplaces.",
  //   Country: 'United States 🇺🇸',
  //   Language: 'English',
  //   RevenueModel: 'Varies by client',
  // },
]

import { site_url } from 'constant/url'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import Modal from '../../utils/Modal'
import Typed from 'react-typed'
import { getAuthKey, getSelectedTenantDetails } from 'constant/functions'
import { getUserDetails } from '@/pages/api/api'
import {
  MixpanelEventName,
  MixpanelPropertiesName,
  MixpanelPropertiesValues,
  MixpanelTracking,
} from 'constant/mixpnael'
import axios from 'axios'

function HeroHome() {
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const router = useRouter()

  const [userDetails, setUserDetails] = useState(null)
  const [tenantDetails, setTenantDetails] = useState(null)

  // user Details and tenant details fetching
  useEffect(() => {
    if (getAuthKey() !== undefined) {
      const auth_key = getAuthKey()
      if (auth_key) {
        getUserDetails(auth_key)
          .then((res) => {
            if (res?.user) {
              setUserDetails(res?.user)
            }
          })
          .catch((error) => {
            if (error?.response?.data?.error?.code === 401) {
              window.location.reload()
            }
          })

        axios
          .get('/api/user_tenants_details', { params: { auth_key: getAuthKey() } })
          .then((res) => {
            setTenantDetails(getSelectedTenantDetails(res.data.data.tenants))
          })
          .catch((err) => {
            if (err?.response?.data.error?.message == 'unauthorized') {
              window.location.reload()
            } else {
              alert(err.response.data.error.message)
            }
          })
      }
    }
  }, [router])

  return (
    <section className="relative">
      {/* Illustration behind hero content */}
      {/* <stop stopColor="#FFF" offset="0%" />
      <stop stopColor="#ccfbf1" offset="77.402%" />
      <stop stopColor="#f0fdfa" offset="100%" /> */}

      {/* <stop stopColor="#FFF" offset="0%" />
      <stop stopColor="#d1fae5" offset="77.402%" />
      <stop stopColor="#ecfdf5" offset="100%" /> */}

      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none  z-[-1]"
        aria-hidden="true"
      >
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#ccfbf1" offset="77.402%" />
              <stop stopColor="#f0fdfa" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="pt-16 pb-12 md:pt-20 md:pb-20">
          {/* Section header */}
          <h1 className=" hidden md:block text-center  text-3xl    font-normal leading-tighter    tracking-wider">
            Launch your
            <Typed
              className="mx-[10px]"
              strings={[
                'Product Marketplace',
                'Multi Vendor Marketplaces',
                'Event Booking Platform',
                'Donation Marketplace',
                ' Directories',
                'App Stores',
              ]}
              typeSpeed={50}
              backSpeed={100}
              loop
            />
          </h1>
          <div className="text-center pb-12 md:pb-16">
            <h1
              className="text-4xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-6  "
              data-aos="zoom-y-out"
            >
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                No-Code Builder + AI
              </span>{' '}
              <br />
              to launch your digital platforms.
            </h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                Launch your project without building from SCRATCH. Developers and Entrepreneurs
                launch digital projects FAST using Tradly.
              </p>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <div>
                  <a
                    className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0"
                    href={`/signup`}
                    onClick={() => {
                      MixpanelTracking(MixpanelEventName.homepage_template, {
                        [MixpanelPropertiesName.user_name]: userDetails?.name,
                        [MixpanelPropertiesName.user_email]: userDetails?.email,
                        [MixpanelPropertiesName.tenant_name]: tenantDetails?.id,
                        [MixpanelPropertiesName.homepage_sticky_buttons_property]:
                          MixpanelPropertiesValues.homepage_sticky_get_started,
                      })
                    }}
                  >
                    Start Building For Free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroHome

import { testimonials } from 'data/testimonials'
import React from 'react'
import TestimonialsCarousel from '../Tradly/TestimonialsCarousel'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import AllIcons from 'constant/AllIcons'

const Testimonials = () => {
  const SwiperButtonNext = () => {
    const swiper = useSwiper()
    return (
      <button
        className="   cursor-pointer  h-8 w-8   absolute  top-0 bottom-0 my-auto flex items-center justify-center z-50  rounded-full  bg-[#ffffff]  right-2 shadow-sm border border-[#959b9f]   "
        onClick={() => swiper.slideNext()}
      >
        <span className="p-2"> {AllIcons.next_icon}</span>
      </button>
    )
  }
  const SwiperButtonPrev = () => {
    const swiper = useSwiper()
    return (
      <button
        className="  cursor-pointer h-8 w-8   absolute  top-0 bottom-0 my-auto flex items-center justify-center z-50  rounded-full  bg-[#ffffff]  left-0 shadow-sm border border-[#959b9f]   "
        onClick={() => swiper.slidePrev()}
      >
        <span className="p-2"> {AllIcons.prev_icon}</span>
      </button>
    )
  }
  return (
    <div>
      {/* <TestimonialsCarousel /> */}
      <Swiper
        slidesPerView="auto"
        slidesPerGroup={1}
        spaceBetween={16}
        loop={false}
        navigation={false}
        className="md:px-[50px]"
        modules={[Navigation, Pagination]}
      >
        {testimonials.map((item, index) => (
          <SwiperSlide
            style={{
              width: '300px',
              minHeight: '100px',
            }}
            key={index}
          >
            <div
              key={index}
              className="text-center px-6 py-8  relative border-2 border-gray-200 rounded bg-white z-10"
            >
              <blockquote className="text-xl font-medium mb-4  line-clamp-[10]">
                {item.text}
              </blockquote>
              <cite className="block font-bold text-lg not-italic mb-1">{item.name}</cite>
              <div className="text-gray-600">
                <span>{item.Country}</span> <p>{item.RevenueModel}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <SwiperButtonPrev />
        <SwiperButtonNext />
      </Swiper>
    </div>
  )
}

export default Testimonials

import Head from 'next/head'
import HeroHome from '@/components/TradlyHome/HeroHome'
import Header2 from '@/components/Header2'
import TradlyFooter from '@/partials/TradlyFooter'
import EnablingEntrepreneurs from '@/components/home/tradly-home/EnablingEntrepreneurs'
import DeveloperExperience from '@/components/home/tradly-home/DeveloperExperience'
import Opensource from '@/components/home/tradly-home/Opensource'
import BuildingBlocks from '@/components/home/tradly-home/BuildingBlocks/BuildingBlocks'
import Modularity from '@/components/home/tradly-home/Modularity'
import AnyDevices from '@/components/home/tradly-home/AnyDevices'
import Integrations from '@/components/home/tradly-home/Integrations'
import Studio from '@/components/home/tradly-home/Studio'
import Featuresstack from '@/components/home/tradly-home/Featuresstack'
import Cta from '@/partials/Cta'
import TemplatesSlider from '@/components/Templates/Index/TemplatesSlider'
import Onboarding_steps_bar from '@/partials/Onboarding_steps_bar'
import { tradly_api_call } from '@/WebHandler/tradly_api_call'
import HeroHome2 from './tradly-home/HeroHome2'
import TemplatesSlider2 from './tradly-home/TemplatesSlider2'
import HomePricingPlan from './tradly-home/HomePricingPlan'
import VideoBlock from './VideoBlock'
import Testimonials from './Testimonials'
import CompaniesUsingUs from './CompaniesUsingUs'
import { useSwiper } from 'swiper/react'

export default function HomePage2({ templates }) {
  return (
    <>
      <Head>
        <meta
          key="twitter:title"
          name="twitter:title"
          content="Tradly - No-code Builder + AI for Marketplaces, AppStore, Commerce & Delivery"
        />
        <meta
          key="og:title"
          property="og:title"
          content="Tradly - No-code Builder + AI for Marketplaces, AppStore, Commerce & Delivery"
        />
        <title>Tradly - No-code Builder + AI for Marketplaces, AppStore, Commerce & Delivery</title>
      </Head>
      <div className="flex  flex-col min-h-screen overflow-hidden    ">
        <div className="max-w-6xl w-full mx-auto px-2 sm:px-6 flex-grow">
          <HeroHome />
          <VideoBlock />
          <hr className="my-20 outline-none bg-transparent border-0 " />
          {/* New Components */}
          <HeroHome2 />
          <TemplatesSlider2 templates={templates} />
          {/* <HomePricingPlan /> */}

          {/*  */}

          <div className=" max-w-7xl mx-auto pt-20 mb-20 space-y-20 overflow-hidden sm:pt-32 sm:mb-32 sm:space-y-32 md:pt-40 md:mb-40 md:space-y-40">
            <div className="flex flex-col   gap-8">
              <CompaniesUsingUs />
              <Testimonials />
            </div>
            {/* <section className="text-center px-8">
              <h2 className="text-gray-900 text-4xl tracking-tight font-extrabold sm:text-5xl">
                “From an Idea to Success Story”
              </h2>
              <figure>
                <blockquote>
                  <p className="mt-6 max-w-4xl mx-auto text-lg">
                    {/* I’ve written{' '}
                  <a
                    href="https://stories.jkbaseer.com"
                    className="text-color10 font-semibold"
                  >
                    a few stories
                  </a>{' '} */}
            {/* "When I decided to launch my Australian based business, I reached out to Tradly
                    to assist me in developing a very specific model of marketplace app. The Tradly
                    Team put together a completely customised app that was to my business's unique
                    specifications in a short timeframe, creating a fantastic customer experience
                    every step of the way. I was, and continue to be, blown away by the way that
                    Tradly has streamlined the white label app process, making an app presence
                    accessible to businesses of all sizes." - Juliette, Wyldeplants
                  </p>
                </blockquote>
              </figure> 
            </section>  */}
            <EnablingEntrepreneurs />

            <Integrations />
            <Modularity />
            <DeveloperExperience />
            <AnyDevices />
            <BuildingBlocks />
            {/* <Designcomponents /> */}
            <Opensource />
            <Studio />
            <Featuresstack />
          </div>
          <Cta />

          <TradlyFooter />
        </div>
      </div>
    </>
  )
}
